<template>
  <div class="news">
    <Nav nums='2' isColor='#fff'/>
    <div class="newsDetails">
        <p class='newTime'>新闻丨<span>{{newInfoCont.updatetime}}</span></p>
        <div class="bigBox">
            <div class="title">
                {{newInfoCont.name}}
            </div>
            <!-- <div class="newsImg">
                <img :src="newInfoCont.images" alt="">
            </div> -->
            <div class="content" v-html="newInfoCont.content">
                {{newInfoCont.content}}
            </div>
            <div class="baseBtn">
                <router-link to="/news">返回</router-link>
                <div class="paging">
                    <div @click='toUp'>上一篇</div>
                    <div @click='toNext'>下一篇</div>
                </div>
            </div>
        </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Floor from './floor.vue'
import { imgHttp, request } from '../apis/http'
import moment from 'moment'
export default {
  components: {
    Nav,
    Floor
  },
  data () {
    return {
        newsId:'',
        index:'',
        type:null,
        newInfoCont:{},
        arr1:[]
    }
  },
  created() {
    this.newsId = this.$route.query.id
    this.type = this.$route.query.type
    this.index = Number(this.$route.query.index)
  },
  beforeMount() {
      this.newsInfo()
      scrollTo(0, 0)
      switch (this.type) {
          case '1':
          this.arr1 = JSON.parse(localStorage.getItem('part1'))
          break
          case '2':
           this.arr1 = JSON.parse(localStorage.getItem('part2'))
          break
      }
  },
  beforeRouteUpdate (to, from, next) {
      if (to.fullPath !== from.fullPath){
          next()
          this.newsInfo()
          location.reload()
          scrollTo(0, 0)
      }
  },
  methods: {
    toUp(){
        if (this.index > 0){
        this.index = this.index - 1
        this.newInfoCont = this.arr1[this.index]
         scrollTo(0, 0)
        }
    },
    toNext(){
        if (this.index < this.arr1.length - 1 && this.index > -1){
        this.index = this.index + 1
        this.newInfoCont = this.arr1[this.index]
        scrollTo(0, 0)
        }
    },
    async newsInfo () {
      const res = await request({
        url: `/api/index/newsinfo?id=${this.newsId}`
      })
      res.data.data.images = imgHttp + res.data.data.images
      res.data.data.updatetime = moment(res.data.data.updatetime * 1000).format('YYYY.MM.DD')
      this.newInfoCont = res.data.data
   }
 }
}
</script>
<style lang="less">
@import './fn.less';
.newsDetails{
    width: 100%;
    padding: 10.4166vw 18.75vw;
    box-sizing: border-box;
    margin-bottom: 10.4166vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .newTime{
        color: #57585C;
        font-size: 1.04166vw;
    }
    .bigBox{
        margin-top: 3.4375vw;
        .title{
            color: #101218;
            font-size: 2.604166vw;
            font-weight: 700;
            line-height: 3.125vw;
        }
        .newsImg{
            margin-top: 6.354166vw;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 37.5vw;
                height: 28.125vw;
            }
        }
        .content{
            margin-top: 5.20833vw;
            color: #57585C;
            font-size: 1.04166vw;
            line-height: 2.08333vw;
            p{
            font-size: 1.04166vw!important;
            span{
            font-family: PuHuiTi!important;
            }
            }
            img{
                .vw(max-width,900)
            }
        }
        .smallTitle{
            color: #101218;
            font-size: 1.5625vw;
            margin-top: 4.3229166vw;
        }
        .baseBtn{
            margin-top: 7.291666vw;
            display: flex;
            justify-content: space-between;
            color: #101218;
            font-size: 1.04166vw;
            a{
                text-decoration: none;
                color: #101218;
            }
            .paging{
                display:flex;
                div{
                    margin-left:2.7604166vw;
                    &:hover{
                        cursor:pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 980px) {
.newsDetails{
    width: 100%;
    padding: 30.4166vw 10.75vw 10vw;
    box-sizing: border-box;
    margin-bottom: 10.4166vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .newTime{
        color: #57585C;
        font-size: 3.84166vw;
    }
    .bigBox{
        margin-top: 3.4375vw;
        .title{
            color: #101218;
            font-size: 6.604166vw;
            font-weight: 700;
            line-height: 7.125vw;
        }
        .newsImg{
            margin-top: 6.354166vw;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 55.5vw;
                height: 35.125vw;
            }
        }
        .content{
            margin-top: 5.20833vw;
            color: #57585C;
            line-height: 6.08333vw;
            font-size: 0.24166vw!important;
            p{
            font-size: 2.448979vw!important;
            img{
                max-width: 50vw!important;
            }
            }
        }
        .smallTitle{
            color: #101218;
            font-size: 1.5625vw;
            margin-top: 4.3229166vw;
        }
        .baseBtn{
            margin-top: 7.291666vw;
            display: flex;
            justify-content: space-between;
            color: #101218;
            font-size: 4.04166vw;
            a{
                text-decoration: none;
                color: #101218;
            }
            .paging{
                a{
                    margin-left:2.7604166vw;
                }
            }
        }
    }
}
  }
</style>
